<template>
  <a-layout>

    <div class="top">
      <div class="top_name">
        <div class="top_name_cn" style="font-size: 15px">盛创云智(浙江)信息技术服务有限公司</div>
        <div class="top_name_en" style="">Shengchuang Yunzhi (Zhejiang) Information Technology Service Co Ltd</div>
      </div>
      <div class="more_box">
        <a href="javascript:;">
          <span>更多</span>
          <img src="@/assets/image/more_pc.png"/>
        </a>
      </div>
    </div>
    <contact-form ref="contact"/>
    <Header></Header>
    <Content></Content>
    <Footer></Footer>
  </a-layout>
</template>

<script>
import Header from './HeaderLayout.vue'
import Content from './ContentLayout.vue'
import Footer from './FooterLayout.vue'
import contactForm from "@/components/pc/contactForm";
import bus from "@/utils/bus";

export default {
  name: "BasicLayout",
  components: {
    Header,
    Content,
    Footer,
    contactForm
  },
  mounted() {
    bus.$on('contactOpenHandle', () => {
      this.$refs.contact.openHandle()
    })
  }
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
  height: 3rem /* 48/16 */;
  background: linear-gradient(90deg, #3C60EA 0%, #8841BE 52%, #EA7F2F 100%);
  align-items: center;
  justify-content: space-between;
  padding-left: 1.25rem /* 20/16 */;
}

.top_name {
  color: #FFFFFF;
  font-family: AliBlack;
  line-height: 0.9375rem /* 15/16 */;
}

.top_name_cn {
  // font-size: 3.2px;
}

.top_name_en {
  transform: scale(0.45);
  margin: 0 0 0 -37.8%;
}

.more_box {
  height: 100%;
  line-height: 3rem /* 48/16 */;
  padding: 0 1.625rem /* 26/16 */;
}

.more_box:hover {
  cursor: pointer;
  background-color: #db772a;
}

.more_box a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.more_box span {
  font-size: 1rem /* 16/16 */;
  font-family: "AlibabaPuHuiTi-2-55";
  font-weight: bold;
  color: #FFFFFF;
  line-height: 3rem /* 48/16 */;
  padding-right: 0.4375rem /* 7/16 */;
}

.more_box img, .right_icon {
  width: 1rem /* 16/16 */;
  height: 0.625rem /* 10/16 */;
}
</style>

