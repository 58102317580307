<template>
  <div v-if="open" class="model" @click="cancelHandle">
    <div class="model-main" @click="(e)=>e.stopPropagation()">
      <div class="title">
        <div class="top">CONTACT US</div>
        <div class="middle">联系我们</div>
        <div class="line"></div>
      </div>
      <div class="model-body">
        <a-form-model ref="form" :label-col="{ span: 5 }" :model="form" :rules="rules" :wrapper-col="{ span: 12 }"
                      layout="vertical">
          <a-form-model-item label="您的称呼" prop="name">
            <a-input v-model="form.name"/>
          </a-form-model-item>
          <a-form-model-item label="您的电话" prop="phone">
            <a-input v-model="form.phone"/>
          </a-form-model-item>
          <a-form-model-item label="写下您的需求和建议" prop="remark">
            <a-textarea v-model="form.remark"/>
          </a-form-model-item>
        </a-form-model>
        <div class="submit" @click="submitHandle">
          提交留言&nbsp;
          <long-right-svg/>
        </div>
      </div>
      <a-icon class="icon" type="close-circle" @click="open =false"/>
    </div>
  </div>
</template>

<script>

import LongRightSvg from "@/components/Icon/longRight";
import axios from "axios";

export default {
  components: {LongRightSvg},
  data() {
    return {
      open: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请填写您的称呼',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: '请填写您的电话',
            trigger: 'change'
          }
        ],
        remark: [
          {
            required: true,
            message: '请写下您的需求和建议',
            trigger: 'change'
          }
        ],
      }
    }
  },
  created() {

  },
  watch: {
    'open': {
      handler(v) {
        if (v) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }

      }
    }
  },
  methods: {
    openHandle() {
      this.form = {}
      this.open = true
    },
    submitHandle() {
      this.$refs.form.validate(valid => {
        if (valid) {
          axios.post("http://filapi.dev.weixue.ltd/notify/scyzcontactus", this.from)
              .then((resp) => {
                this.show_from = false;
                this.from = {
                  name: '',
                  mobile: ''
                };
                alert('提交成功')
                this.open = false
              });
        }
      })
    },
    cancelHandle(e) {
      this.open = false
    }
  }

}
</script>

<style lang='less' scoped>
.model {
  position: fixed;
  width: 100vw;
  overflow: auto;
  height: 100vh;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.66);
}

.model-main {
  overflow: hidden;
  margin: 10% auto;
  width: 39.875rem /* 638/16 */;
  padding: 1.375rem /* 22/16 */ 3.4375rem /* 55/16 */ 3.9375rem /* 63/16 */;
  background: #FFFFFF;
  box-shadow: 0rem /* 0/16 */ 0rem /* 0/16 */ 0.375rem /* 6/16 */ 0.0625rem /* 1/16 */ rgba(0, 0, 0, 0.06);
  border-radius: 1.75rem /* 28/16 */;
  position: relative;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.9375rem /* 31/16 */;

    .top {
      font-size: 0.75rem /* 12/16 */;
    }

    .middle {
      font-weight: bold;
      font-size: 1.75rem /* 28/16 */;
      margin: 0 0 0.625rem /* 10/16 */;
    }

    .line {
      height: 0.25rem /* 4/16 */;
      width: 3.875rem /* 62/16 */;
      background: #3C60EA;
    }
  }
}

.model-body ::v-deep {
  width: 90%;
  margin: 0 auto;

  *, *:before, *:active {
    font-size: 0.875rem /* 14/16 */;
    margin: 0;
  }

  label, .ant-form-explain {
    font-size: 0.875rem /* 14/16 */;
    line-height: 2.875rem;
    margin: 0.1875rem /* 3/16 */ 0;
  }

  input {
    width: 100%;
    height: 2.875rem /* 46/16 */;
    padding: 0 1.25rem /* 20/16 */;

    &:focus {
      box-shadow: 0 0 0 0.1875rem /* 3/16 */ rgba(24, 144, 255, 0.2);
    }
  }


  input, textarea {
    border-radius: 1.75rem /* 28/16 */;
    border-color: #CEE1FF;
  }

  textarea {
    min-height: 6.125rem;
    padding: 1.25rem /* 20/16 */;

    &:focus {
      box-shadow: 0 0 0 0.1875rem /* 3/16 */ rgba(24, 144, 255, 0.2);
    }
  }

  .ant-form-explain {
    min-height: 1.25rem /* 20/16 */;
    line-height: 1.25rem /* 20/16 */;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .ant-form-item {
    padding-bottom: 0.5rem /* 50/16 */;
  }
}

.submit, .submit:active {
  margin: 1.25rem /* 20/16 */ auto 0;
  border-radius: 1.75rem /* 28/16 */;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.875rem /* 62/16 */;
  background: #3A61EA;
  color: #fff;
  font-size: 1.25rem /* 20/16 */;
}

.icon {
  position: absolute;
  top: 1.125rem /* 18/16 */;
  right: 1.125rem /* 18/16 */;
  font-size: 1.875rem /* 30/16 */;
}
</style>
