<template>
  <div>
    <div class="feedback">反馈</div>
    <div class="help"></div>
  </div>
</template>

<script>

export default {
  name:'fixedPart',
}
</script>

<style scoped lang='less'>
.feedback,.help{
  position: fixed;
  right: 0;
}
.feedback{
  bottom: 160px;
  width: 34px;
  height: 52px;
  background: #6D30F1;
  padding: 10px;
  border-radius: 4px 0px 0px 4px;
  font-size: 12px;
  color: #fff;
}
.help{
  bottom: 60px;
  width: 30px;
  height: 30px;
  background: url("~@/assets/image/help.png") no-repeat;
  background-size: 100%;
}
</style>
