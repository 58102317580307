<template>
  <a-layout-footer class="footer">
    <div class="footer_root">
      <ul class="footer_menu page-center">
        <li>
          <p class="item" @click="push('home')">首页</p>
        </li>
        <li>
          <p class="item" @click="push('tob')">ToB产品</p>
        </li>
        <li>
          <p>案例展示</p>
          <ul>
            <li><a @click="push('bCase')">B端案例</a></li>
            <li><a @click="push('cCase')">C端案例</a></li>
          </ul>
        </li>
        <li>
          <p class="item" @click="push('design')">品牌设计</p>
        </li>
        <li>
          <p class="item" @click="push('introduce','team')">企业介绍</p>
          <ul>
            <li><a @click="push('introduce','team')">团队介绍</a></li>
            <li><a @click="push('introduce','partner')">大型合作机构</a></li>
            <li><a @click="push('introduce','technology')">技术栈</a></li>
            <li><a @click="push('introduce','qualifications')">资质证书</a></li>
          </ul>
        </li>
        <li>
          <p class="item" @click="push('contact')">联系我们</p>
        </li>
      </ul>
      <div class="cpr  page-center">
        <a class="scyz_logo" href="/">
          <img src="@/assets/image/scyz_logo.png">
          <span>盛创云智</span>
        </a>
        <p>
          @ 2023 盛创云智 shengchuangyunzhi 版权所有
          <!--                     | <a href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=a2cmq.17630000.J_9220772140.54.7b5179feueLyVP" target="_blank">浙公网安备 33010602009975号</a>-->
          | <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备19011238号-2</a>
        </p>
        <ul class="cpr_ul">
          <li><a href="javascript:;">服务条款</a></li>
          <li><a href="javascript:;">隐私政策</a></li>
        </ul>
      </div>
    </div>
  </a-layout-footer>
</template>

<script>


export default {
  name: 'FooterLayout',
  components: {},
  computed: {
    routeName() {
      return this.$route.name
    },
    paramsName() {
      return this.$route.params.type
    }
  },
  methods: {
    push(name, type) {
      if (this.routeName === name) {
        if (this.paramsName === type) {
          return
        }
        this.$router.push({
          name,
          params: type ? {type} : {}
        })
        return
      }
      this.$router.push({
        name,
        params: type ? {type} : {}
      })
    },

  }

}
</script>

<style lang="less" scoped>

.footer_root {
  background: #fff;
}

.footer_menu {
  gap: 5rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 2.5rem /* 40/16 */ 0 5.8125rem 0;
  list-style: none;
  flex-direction: row;
  justify-content: flex-start;

  p {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #000000;
    font-size: 1rem /* 16/16 */;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0;

  }

  .item:hover {
    cursor: pointer;
    color: #3C60EA;
  }

  ul {
    margin: 0;
    display: flex;
    padding: 0;
    align-items: flex-start;
    flex-direction: column;

    li {
      line-height: 1.875rem /* 30/16 */;
      font-weight: normal;
      font-size: 0.875rem /* 14/16 */;

      a {
        color: #A7A7A7;
      }
    }

    li:hover {
      cursor: pointer;

      a {
        color: #3C60EA;
      }
    }

  }
}

.cpr {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  border-top: 0.0625rem /* 1/16 */ solid #E8EBF2;
  padding: 1.125rem /* 18/16 */ 0;
  gap: 1.5625rem /* 25/16 */;
}

.scyz_logo {
  text-decoration: none;
  padding-left: 0.3125rem /* 5/16 */;
  display: flex;
  align-items: center;

  img {
    width: 2.3125rem /* 37/16 */;
    height: 1.6875rem /* 27/16 */;
  }

  span {
    padding-left: 0.625rem /* 10/16 */;
    color: #3C60EA;
    font-size: 1.5rem /* 24/16 */;
    font-weight: bold;
  }
}

.cpr p {
  font-size: 0.875rem /* 14/16 */;
  color: #A7A7A7;
  flex-grow: 1;

  a {
    color: #A7A7A7;
  }

  a:hover {
    color: #3C60EA;
  }
}

.cpr_ul {
  flex: 1;

  li {
    cursor: pointer;
    font-size: 0.875rem /* 14/16 */;
    float: right;
    margin-left: 4.8125rem /* 77/16 */;

    a {
      color: #A7A7A7;
    }

    a:hover {
      color: #3C60EA;
    }
  }
}
</style>
