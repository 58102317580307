import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/home.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/about.vue')
    },
    {
        path: '/tob',
        name: 'tob',
        component: () => import('@/views/tob')
    },
    {
        path: '/bCase',
        name: 'bCase',
        component: () => import('@/views/bCase')
    },
    {
        path: '/cCase',
        name: 'cCase',
        component: () => import('@/views/cCase')
    },
    {
        path: '/introduce/:type?',
        name: 'introduce',
        component: () => import('@/views/introduce')
    },
    {
        path: '/design',
        name: 'design',
        component: () => import('@/views/design')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/contact')
    },
]

const router = new VueRouter({
    routes,
    mode: 'history'
})
router.beforeEach((to, from, next) => {
    console.log(to, from)
    if (to.name === from.name) {
        next()
        return
    }
    window.scrollTo(0, 0); // 在每次路由跳转前将页面滚动条置顶
    next();
});
export default router
