import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Carousel, FormModel, Icon, Input, Layout, Menu} from 'ant-design-vue';
import './global.less';
import PCBaseLayout from './components/pc/BasicLayout.vue'
import MobileBaseLayout from './components/mobile/BasicLayout.vue'
import 'ant-design-vue/lib/menu/style/index.css'
import 'ant-design-vue/lib/carousel/style/index.css'
import 'ant-design-vue/lib/input/style/index.css'
import 'ant-design-vue/lib/form-model/style/index.css'
import utils from "@/utils";


Vue.config.productionTip = false
Vue.component(Layout.name, Layout);
Vue.component(Layout.Header.name, Layout.Header);
Vue.component(Layout.Content.name, Layout.Content);
Vue.component(Layout.Footer.name, Layout.Footer);
Vue.component(Icon.name, Icon);
Vue.use(Menu);
Vue.use(Carousel);
Vue.use(Input);
Vue.use(FormModel);
Vue.use(utils)
Vue.component("PCBaseLayout", PCBaseLayout);
Vue.component("MobileBaseLayout", MobileBaseLayout);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
