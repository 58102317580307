<template>
  <a-layout-header style="position: sticky;top: 0;z-index: 1000">
    <div ref="top" :class="topVisiable&&'top_visiable'" class="top">
      <div class="top_name">
        <div class="top_name_cn">
          盛创云智(浙江)信息技术服务有限公司
        </div>
        <div class="top_name_en">
          Shengchuang Yunzhi (Zhejiang) Information Technology Service Co Ltd
        </div>
      </div>
    </div>
    <div
        :class="activeClass"
        :style="{ backgroundColor: backgroundColor }"
        class="menu_box"
    >
      <div :class="whiteColor?'whiteColor':''"
           class="left">
        <span class="menu_image_btn">
        </span>
        <a class="scyz_logo" href="/">
          <img v-if="whiteColor" src="@/assets/image/scyz_logo_white.png"/>
          <img v-else src="@/assets/image/scyz_logo.png"/>
          <span>盛创云智</span>
        </a>
      </div>
      <div :class="whiteColor?'whiteColor':''"
           class="right"><a v-if="menuVisiable" @click="menuTaggle">
        <MenuIcon class="menu_icon"/>
      </a> <a v-else @click="menuTaggle">菜单</a>
      </div>
    </div>
    <menu_m :class="menuVisiable?'visiable':''" class="menu" @clickHandle="menuClickHandle"/>
    <fixed-part v-show="topVisiable"/>
  </a-layout-header>
</template>

<script>
import MenuIcon from "../Icon/menu.vue";
import FixedPart from "./fixedPart";
import Menu_m from "@/components/mobile/menu";

export default {
  name: "HeaderLayout",
  components: {
    Menu_m,
    FixedPart,
    MenuIcon
  },
  data() {
    return {
      backgroundColor: "",
      activeClass: "header_root",
      topVisiable: false,
      menuVisiable: false
    };
  },
  computed: {
    whiteColor() {
      return this.$route.name === 'tob' && !this.topVisiable
    }
  },
  watch: {
    'menuVisiable': {
      handler(v) {
        if (v) {
          document.body.style.overflow = 'hidden'
          this.backgroundColor = '#fff'
        } else {
          this.handleScroll()
          document.body.style.overflow = 'auto'
        }
      }
    }
  },
  methods: {
    menuClickHandle() {
      this.menuVisiable = false
    },
    handleScroll() {
      const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      if (scrollTop) {
        this.backgroundColor = `rgba(252, 252, 252,${
            scrollTop / (scrollTop + 3)
        })`;
        if (scrollTop >= this.$refs.top.offsetHeight) {
          this.topVisiable = true
        }
        if (this.activeClass != "header_root_active") {
          this.activeClass = "header_root_active";
        }
      } else if (scrollTop === 0) {
        this.backgroundColor = "transparent";
        this.activeClass = "header_root";
        this.topVisiable = false
      }
    },
    menuTaggle() {
      if (!this.topVisiable) {
        this.topVisiable = !this.topVisiable
      }
      this.menuVisiable = !this.menuVisiable
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;
  height: 50px;
  background: linear-gradient(90deg, #3c60ea 0%, #8841be 52%, #ea7f2f 100%);
  padding: 10px 0px 10px 15px;
  transition: .5s;
}

.top_visiable {
  height: 0;
  padding: 0px 0px 0px 15px;
}

.top_name {
  color: #ffffff;
  font-family: AliBlack;
  display: flex;
  flex-direction: column;
}

.top_name_cn {
  font-size: 16px;
  white-space: nowrap;
}

.top_name_en {
  font-size: 12px;
  white-space: nowrap;
  transform: scale(0.45);
  margin: -3px 0 0 -38%;
}

.header_root {
  position: absolute;
  bottom: -45px;
  z-index: 1000;
  background-color: transparent;
}

.header_root_active {
  position: absolute;
  bottom: -45px;
  z-index: 1000;
  background-color: transparent;
  box-shadow: 0px 2px 5px rgba(26, 36, 57, 0.1);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  transition-property: transform, box-shadow, padding-top, padding-bottom, background-color !important;
}

.menu_box {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 15px;

  .left {
    display: flex;
    align-items: center;

    .menu_image_btn {
      border: none;
      background-color: transparent;
      cursor: pointer;
      display: contents;
    }

    .menu_icon {
      color: #8a8a8a;
    }

    .scyz_logo {
      text-decoration: none;
      padding-left: 5px;
      display: flex;
      align-items: center;

      img {
        width: 26px;
        height: 20px;
      }

      span {
        padding-left: 5px;
        color: #3c60ea;
        font-size: 14px;
        font-family: "AlibabaPuHuiTi-2-85";
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    a {
      color: #3c60ea;
      font-family: "AlibabaPuHuiTi-2-85";
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.menu {
  overflow: hidden;
  position: absolute;
  top: 45px;
  right: 0;
  height: calc(100vh - 45px);
  width: 0%;
  background: #fff;
  transition: .3s;
}

.visiable {
  width: 100%;
}

.whiteColor {
  * {
    color: #fff !important;

  }
}
</style>

