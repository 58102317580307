<template>
  <a-layout-footer class="footer">
    <menu_m ref="menu" class="menu"/>
    <div class="cpr">
      <a class="scyz_logo" href="/">
        <img src="@/assets/image/scyz_logo.png">
        <span>盛创云智</span>
      </a>
      <div class="copy">
        @ 2023 盛创云智 shengchuangyunzhi<br/>
        版权所有&nbsp;|&nbsp;into@shengchuangyunzhi.com
      </div>
      <ul class="cpr_ul">
        <li><a href="javascript:;">服务条款</a></li>
        <li><a href="javascript:;">隐私政策</a></li>
      </ul>
    </div>
  </a-layout-footer>
</template>

<script>


import Menu_m from "@/components/mobile/menu";

export default {
  name: 'FooterLayout',
  components: {Menu_m},
  watch: {
    '$route': {
      handler(v) {
        this.$refs.menu.selectedKeys = []
        this.$refs.menu.openKeys = []
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background: #fff;
  padding: 18px;
}

.list {
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;

  &_item {
    position: relative;
    padding: 14px 0;

    .text {
      font-size: 14px;
    }

    &:after {
      content: '';
      width: 7px;
      height: 7px;
      border-bottom: 2px solid #272636;
      border-right: 2px solid #272636;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}


.cpr {
  display: flex;
  flex-direction: column;
}

.scyz_logo {
  display: flex;
  margin-bottom: 10px;

  img {
    width: 23px;
    height: 17px;
  }

  span {
    padding-left: 6px;
    color: #3C60EA;
    font-size: 14px;
    font-family: "AlibabaPuHuiTi-2-85";
    font-weight: bold;
  }
}

.copy, .cpr_ul li a {
  font-size: 10px;
  color: #A7A7A7;
  line-height: 17px;
}

.cpr_ul {
  margin-top: 7px;
  display: flex;

  li {
    margin-right: 10px;
  }
}

.menu {
  margin-bottom: 10px;
}

/deep/ .ant-menu-sub {

  & > .ant-menu-item {
    padding-left: 10px !important;
    font-size: 14px !important;
  }
}


/deep/ .ant-menu-root {
  & > .ant-menu-item {
    padding-left: 0 !important;
    font-size: 16px;
    font-weight: normal;
  }
}


/deep/ .ant-menu-submenu-title {
  padding-left: 0 !important;

  * {
    font-size: 16px;
    font-weight: normal;
  }
}

/deep/ .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0;
}


</style>
