<template>
  <a-layout-content class="content_root">
    <router-view/>
  </a-layout-content>
</template>

<script>


export default {
  name: 'ContentLayout',
  components: {}
}
</script>

<style lang="less" scoped>
.content_root ::v-deep {
  z-index: 1;
  margin-top: -4.6875rem /* -75/16 */;

  .title {
    font-size: 2.25rem /* 36/16 */;
    font-weight: bold;
    margin: 4.6875rem /* 75/16 */ auto;
    text-align: center;
  }

}


</style>
