<template>
  <div id="app">
    <MobileBaseLayout v-if="$store.state.device=='m'"/>
    <PCBaseLayout v-else></PCBaseLayout>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import {throttle} from 'lodash-es'

export default {
  created() {
    this.resizeChange = throttle(this.resizeChange, 200)
    this.resizeChange()
    window.addEventListener('resize', this.resizeChange, false)
  },

  methods: {
    ...mapMutations(['setDevice']),
    resizeChange() {
      if (document.documentElement.clientWidth > 960) {
        this.setDevice('pc')
      } else {
        this.setDevice('m')
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChange, false)
  }
}
</script>
