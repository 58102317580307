<template>
  <div>
    <contact-form ref="contact"/>
    <a-layout>
      <Header></Header>
      <Content></Content>
      <Footer></Footer>
    </a-layout>
  </div>

</template>

<script>
import Header from './HeaderLayout.vue'
import Content from './ContentLayout.vue'
import Footer from './FooterLayout.vue'
import ContactForm from "@/components/mobile/contactForm";
import bus from "@/utils/bus";

export default {
  name: "BasicLayout",
  components: {
    ContactForm,
    Header,
    Content,
    Footer
  },
  mounted() {
    bus.$on('contactOpenHandle', () => {
      this.$refs.contact.openHandle()
    })
  }
};

</script>
