
<template>
    <a-layout-content class="content_root">
        <router-view />
    </a-layout-content>
</template>
  
<script>


export default {
    name: 'ContentLayout',
    components: {
    }
}
</script>
  
<style lang="less" scoped>
</style>
  