<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 5.667c0-.62 0-.93.068-1.185a2 2 0 0 1 1.414-1.414C4.737 3 5.047 3 5.667 3c.31 0 .465 0 .592.034a1 1 0 0 1 .707.707C7 3.868 7 4.023 7 4.333V5c0 .943 0 1.414-.293 1.707C6.414 7 5.943 7 5 7h-.667c-.31 0-.465 0-.592-.034a1 1 0 0 1-.707-.707C3 6.132 3 5.977 3 5.667Z"
      fill="currentColor"></path>
    <rect x="3" y="10" width="4" height="4" rx="1" fill="currentColor"></rect>
    <path
      d="M3 18.333c0-.31 0-.465.034-.592a1 1 0 0 1 .707-.707C3.868 17 4.023 17 4.333 17H5c.943 0 1.414 0 1.707.293C7 17.586 7 18.057 7 19v.667c0 .31 0 .465-.034.592a1 1 0 0 1-.707.707C6.132 21 5.977 21 5.667 21c-.62 0-.93 0-1.185-.068a2 2 0 0 1-1.414-1.414C3 19.263 3 18.953 3 18.333Z"
      fill="currentColor"></path>
    <rect x="10" y="3" width="4" height="4" rx="1" fill="currentColor"></rect>
  <rect x="10" y="10" width="4" height="4" rx="1" fill="currentColor"></rect>
  <rect x="10" y="17" width="4" height="4" rx="1" fill="currentColor"></rect>
  <path
    d="M17 4.333c0-.31 0-.465.034-.592a1 1 0 0 1 .707-.707C17.868 3 18.023 3 18.333 3c.62 0 .93 0 1.185.068a2 2 0 0 1 1.414 1.414c.068.255.068.565.068 1.185 0 .31 0 .465-.034.592a1 1 0 0 1-.707.707C20.132 7 19.977 7 19.667 7H19c-.943 0-1.414 0-1.707-.293C17 6.414 17 5.943 17 5v-.667Z"
    fill="currentColor"></path>
  <rect x="17" y="10" width="4" height="4" rx="1" fill="currentColor"></rect>
  <path
    d="M17 19c0-.943 0-1.414.293-1.707C17.586 17 18.057 17 19 17h.667c.31 0 .465 0 .592.034a1 1 0 0 1 .707.707c.034.127.034.282.034.592 0 .62 0 .93-.068 1.185a2 2 0 0 1-1.414 1.414c-.255.068-.565.068-1.185.068-.31 0-.465 0-.592-.034a1 1 0 0 1-.707-.707C17 20.132 17 19.977 17 19.667V19Z"
    fill="currentColor"></path>
</svg></template>

<script>
export default {
  name: "MenuSvg",
};
</script>
