<template>
  <a-layout-header :class="activeClass"
                   :style="{ backgroundColor: backgroundColor}">
    <div :class="isTob&&backgroundColor!=='#fff'?'white':''" class="menu_root page-center ">
      <div class="menu_box ">
        <button class="menu_image_btn">
          <MenuIcon class="menu_icon"/>
        </button>
        <a class="scyz_logo" href="/">
          <img v-if="!isTob" src="@/assets/image/scyz_logo.png">
          <img v-else src="@/assets/image/scyz_logo_white.png">
          <span>盛创云智</span>
        </a>
        <ul class="menu_ul">
          <li :class="routeName==='home'&&'active'">
            <span @click="push('home')">首页 </span>
          </li>
          <li :class="routeName==='tob'&&'active'">
            <span @click="push('tob')">ToB产品 </span>
          </li>
          <li :class="['bCase','cCase'].includes(routeName)&&'active'"
              @mouseleave="caseMouseHandle('caseMenuOpen',false)" @mousemove="caseMouseHandle('caseMenuOpen',true)">
              <span>案例展示 <DownIcon
                  class="down_icon"/></span>
            <div :class="caseMenuOpen?'':'none'" class="case-menu">
              <div class="page-center case-menu-inner">
                <div :class="routeName === 'bCase'&&'caseActive'" class="case-item" @click="push('bCase')">
                  <img src="~@/assets/image/B-case.png">
                  <div class="text">
                    <div class="title">B端案例</div>
                    <div class="title-en">B-Ens Case</div>
                    <div class="title-tip">为传统制造业提供数字化转型</div>
                  </div>
                </div>
                <div :class="routeName === 'cCase'&&'caseActive'" class="case-item" @click="push('cCase')">
                  <img src="~@/assets/image/C-case.png">
                  <div class="text">
                    <div class="title">c端案例</div>
                    <div class="title-en">C-Ens Case</div>
                    <div class="title-tip">更多案例在线查看</div>
                  </div>
                </div>
                <div class="case-more">
                  Read More &nbsp;
                  <long-right-svg class="icon" color="#4D51F1"/>
                </div>
              </div>
            </div>
          </li>
          <li :class="routeName==='design'&&'active'">
            <span @click="push('design')">品牌设计 </span>
          </li>
          <li :class="routeName==='introduce'&&'active'"
              style="position: relative"
              @mouseleave="caseMouseHandle('introduceMenuOpen',false)"
              @mousemove="caseMouseHandle('introduceMenuOpen',true)">
            <span>企业介绍 <DownIcon class="down_icon"/></span>
            <div :class="introduceMenuOpen?'':'none'" class="introduce-menu">
              <div :class="paramsName==='team'&&'active'" class="introduce-item" @click="push('introduce','team')">
                <span>
                   团队介绍
                <right-svg class="icon"/>
                </span>
              </div>
              <div :class="paramsName==='partner'&&'active'" class="introduce-item"
                   @click="push('introduce','partner')">
                <span>
                合作伙伴
                <right-svg class="icon"/>
                </span>
              </div>
              <div :class="paramsName==='technology'&&'active'" class="introduce-item"
                   @click="push('introduce','technology')">
                <span>

                技术栈
                <right-svg class="icon"/>
                </span>
              </div>
              <div :class="paramsName==='qualifications'&&'active'" class="introduce-item"
                   @click="push('introduce','qualifications')">
                <span>
                资质证书
                <right-svg class="icon"/>
                </span>
              </div>
            </div>
          </li>
          <li :class="routeName==='contact'&&'active'">
            <span @click="push('contact')">联系我们 </span>
          </li>
        </ul>
        <div class="version">
          <a href="javascript:;">版本号1.0</a>
        </div>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import MenuIcon from '../Icon/menu.vue'
import DownIcon from '../Icon/down_menu.vue'
import LongRightSvg from "@/components/Icon/longRight";
import RightSvg from "@/components/Icon/longRight";

export default {
  name: 'HeaderLayout',
  components: {
    MenuIcon,
    DownIcon,
    LongRightSvg,
    RightSvg
  },
  data() {
    return {
      backgroundColor: '',
      activeClass: 'header_root',
      caseMenuOpen: false,
      introduceMenuOpen: false,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    paramsName() {
      return this.$route.params.type
    },
    isTob() {
      return this.routeName === 'tob' && this.activeClass !== 'header_root_active'
    }
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop) {
        this.backgroundColor = `rgba(252, 252, 252,${scrollTop / (scrollTop + 3)})`
        if (this.activeClass != "header_root_active") {
          this.activeClass = 'header_root_active'
        }
      } else if (scrollTop === 0) {
        this.backgroundColor = 'transparent'
        this.activeClass = 'header_root'
      }
    },
    push(name, type) {
      if (this.routeName === name) {
        if (this.paramsName === type) {
          return
        }
        this.$router.push({
          name,
          params: type ? {type} : {}
        })
        return
      }
      this.$router.push({
        name,
        params: type ? {type} : {}
      })
    },
    caseMouseHandle(data, check) {
      this[data] = check
      if (data === 'introduceMenuOpen') {
        return
      }
      if (check) {
        this.backgroundColor = '#fff'
      } else {
        this.handleScroll()
      }
    },
  },
  mounted() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>

<style lang="less" scoped>
.header_root {
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: transparent;
}

.header_root_active {
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: transparent;
  box-shadow: 0rem /* 0/16 */ 0.125rem /* 2/16 */ 0.3125rem /* 5/16 */ rgba(26, 36, 57, 0.1);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  transition-property: transform, box-shadow, padding-top, padding-bottom, background-color !important;
}

.menu_root {
}

.menu_box {
  display: flex;
  width: 100%;
  align-items: center;
  height: 4.6875rem /* 75/16 */;
  line-height: 4.6875rem /* 75/16 */;
  // background-color: antiquewhite;
}

.menu_image_btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menu_icon {
  color: #8a8a8a;
}

.menu_icon:hover {
  color: #3C60EA;
}

.scyz_logo {
  text-decoration: none;
  padding-left: 0.6875rem /* 11/16 */;
  display: flex;
  align-items: center;

  img {
    width: 2.3125rem /* 37/16 */;
    height: 1.6875rem /* 27/16 */;
  }

  span {
    padding-left: 0.75rem /* 12/16 */;
    color: #3C60EA;
    font-size: 1.5rem /* 24/16 */;
    font-family: "AlibabaPuHuiTi-2-85";
    font-weight: bold;
    white-space: nowrap;
  }
}

.menu_ul {
  color: #1f2226;
  margin: 0;
  padding: 0 4.125rem;
  display: flex;
  list-style: none;
  justify-content: space-around;
  flex: 1;

  li {
    cursor: pointer;

    span {
      font-family: "AlibabaPuHuiTi-2-55";
      font-weight: bold;
      font-size: 1rem /* 16/16 */;
      white-space: nowrap;
    }

    .down_icon {
      margin: 0 0 0.3125rem /* 5/16 */ 0.375rem /* 6/16 */;
    }
  }


  //li:not(:first-child) {
  //  margin-left: 15px;
  //}
}

.menu_ul li:hover {
  & > span {
    color: #3C60EA !important;
  }
}

.version {
  a {
    text-decoration: none;
    float: right;
    background-color: #000000;
    color: #fff;
    font-family: "AlibabaPuHuiTi-2-75";
    font-weight: bold;
    line-height: 2.375rem /* 38/16 */;
    width: 9.4375rem /* 151/16 */;
    height: 2.375rem /* 38/16 */;
    font-size: 1rem /* 16/16 */;
    border-radius: 0.5rem /* 8/16 */;
    white-space: nowrap;
    text-align: center;
  }
}

.white {
  ul > li > span {
    color: #fff;
  }
}

.case-menu {
  transition: .2s;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5.8125rem /* 109/16 */ 0 2.125rem /* 34/16 */;
  z-index: -1;
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;

  .case-menu-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .case-item {

    * {
      //font-family: "AlibabaPuHuiTi-2-20";
    }

    width: 31%;
    height: 6.875rem /* 110/16 */;
    border-radius: 1.125rem /* 18/16 */;
    background: #F2F5FA;
    display: flex;
    align-items: center;
    line-height: 1;

    img {
      width: 5.25rem /* 84/16 */;
      height: 5.25rem /* 84/16 */;
      margin: 0 1.5rem;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .title {
        font-size: 1.25rem;
        font-weight: bold;
        color: #4d51f1;
      }

      .title-en {
        font-size: 1rem;
        line-height: 2rem;
      }

      .title-tip {
        font-size: 1.1rem;
      }
    }

    &:hover {
      background: rgba(77, 81, 241, 0.22);
    }
  }

  .case-more {
    width: 31%;
    height: 6.875rem /* 110/16 */;
    border-radius: 1.125rem /* 18/16 */;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4D51F1;
    border: 0.0625rem solid #3C60EA;

    .icon {
      width: 1.3125rem;
      height: 0.8125rem;
    }

  }

  .caseActive {
    background: rgba(77, 81, 241, 0.22);
  }
}

.introduce-menu {
  transition: .2s;
  overflow: hidden;
  height: 9.9375rem /* 159/16 */;
  width: 9rem /* 144/16 */;
  position: absolute;
  top: 4.0625rem /* 65/16 */;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 0.8125rem /* 13/16 */;
  padding: 1rem /* 16/16 */ 1.4375rem /* 23/16 */;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .introduce-item {
    display: flex;
    align-items: center;
    font-size: 0.875rem /* 14/16 */;
    line-height: 1.25rem /* 20/16 */;

    &:hover {
      color: #3A61EA;
    }

    .icon {
      margin-left: 0.5rem /* 8/16 */;
    }
  }
}

/deep/ .active {
  & > span {
    color: #3A61EA !important;
  }
}

.none {
  padding-top: 0;
  padding-bottom: 0;
  height: 0px !important;


  * {
    display: none !important;
  }
}
</style>

