<template>
  <svg :fill="color" height="6.342" viewBox="0 0 10.319 6.342" width="10.319" xmlns="http://www.w3.org/2000/svg">
    <path class="a" d="M136.129,258.594H128v1.153h8.129l-1.787,1.787.807.807,3.171-3.171L135.206,256l-.807.807Z"
          transform="translate(-128 -256)"/>
  </svg>
</template>

<script>
export default {
  name: "LongRightSvg",
  props: {
    color: {
      type: String,
      default: '#BFC6D0'
    }
  }
};
</script>
