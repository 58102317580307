<template>
  <div v-if="open" class="model" @click="cancelHandle">
    <div class="model-main" @click="(e)=>e.stopPropagation()">
      <div class="title">
        <div class="top">CONTACT US</div>
        <div class="middle">联系我们</div>
        <div class="line"></div>
      </div>
      <div class="model-body">
        <a-form-model ref="form" :label-col="{ span: 5 }" :model="form" :rules="rules" :wrapper-col="{ span: 12 }"
                      layout="vertical">
          <a-form-model-item label="您的称呼" prop="name">
            <a-input v-model="form.name"/>
          </a-form-model-item>
          <a-form-model-item label="您的电话" prop="phone">
            <a-input v-model="form.phone"/>
          </a-form-model-item>
          <a-form-model-item label="写下您的需求和建议" prop="remark">
            <a-textarea v-model="form.remark"/>
          </a-form-model-item>
        </a-form-model>
        <div class="submit" @click="submitHandle">
          提交留言&nbsp;
          <long-right-svg/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import LongRightSvg from "@/components/Icon/longRight";
import axios from "axios";

export default {
  components: {LongRightSvg},
  data() {
    return {
      open: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请填写您的称呼',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: '请填写您的电话',
            trigger: 'change'
          }
        ],
        remark: [
          {
            required: true,
            message: '请写下您的需求和建议',
            trigger: 'change'
          }
        ],
      }
    }
  },
  created() {

  },
  watch: {
    'open': {
      handler(v) {
        if (v) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }

      }
    }
  },
  methods: {
    openHandle() {
      this.form = {}
      this.open = true
    },
    submitHandle() {
      this.$refs.form.validate(valid => {
        if (valid) {
          axios.post("http://filapi.dev.weixue.ltd/notify/scyzcontactus", this.from)
              .then((resp) => {
                this.show_from = false;
                this.from = {
                  name: '',
                  mobile: ''
                };
                alert('提交成功')
                this.open = false
              });
        }
      })
    },

    cancelHandle(e) {
      this.open = false
    }
  }

}
</script>

<style lang='less' scoped>
.model {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.66);
}

.model-main {
  overflow: hidden;
  margin: 10% auto;
  width: 297px;
  padding: 27px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
  border-radius: 14px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    .top {
      font-size: 14px;
    }

    .middle {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 6px;
    }

    .line {
      height: 2px;
      width: 63px;
      background: #3C60EA;
    }
  }
}

.model-body {
  width: 90%;
  margin: 0 auto;
}

.submit {
  margin: 0 auto;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 29px;
  background: #3A61EA;
  color: #fff;
}
</style>
