<template>
  <div class="main">
    <a-menu :open-keys="openKeys" :selectedKeys="selectedKeys" mode="inline" style="width: 100%"
            @click="clickHandle" @openChange="onOpenChange">
      <a-menu-item key="home">
        首页
      </a-menu-item>
      <a-menu-item key="tob">
        ToB产品
      </a-menu-item>
      <a-sub-menu key="case">
        <span slot="title"><span>案例展示</span></span>
        <a-menu-item key="bCase" class="case">
          <div class="case-item">
            <img src="~@/assets/image/B-case.png">
            <div class="text">
              <div class="title">B端案例</div>
              <div class="title-en">B-Ens Case</div>
              <div class="title-tip">为传统制造业提供数字化转型</div>
            </div>
          </div>
        </a-menu-item>
        <a-menu-item key="cCase" class="case">
          <div class="case-item">
            <img src="~@/assets/image/C-case.png">
            <div class="text">
              <div class="title">c端案例</div>
              <div class="title-en">C-Ens Case</div>
              <div class="title-tip">更多案例在线查看</div>
            </div>
          </div>
        </a-menu-item>
        <a-menu-item key="more" class="case">
          <div class=" case-more">
            Read More &nbsp;
            <long-right-svg color="#4D51F1"/>
          </div>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item key="design">
        品牌设计
      </a-menu-item>
      <a-sub-menu key="introduce">
        <span slot="title"><span>企业介绍</span></span>
        <a-menu-item key="team">
          团队介绍
          <right-svg class="icon"/>
        </a-menu-item>
        <a-menu-item key="partner">
          合作伙伴
          <right-svg class="icon"/>
        </a-menu-item>
        <a-menu-item key="technology">
          技术栈
          <right-svg class="icon"/>
        </a-menu-item>
        <a-menu-item key="qualifications">
          资质证书
          <right-svg class="icon"/>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item key="contact">
        联系我们
      </a-menu-item>
    </a-menu>
  </div>
</template>
<script>

import RightSvg from "@/components/Icon/longRight";
import LongRightSvg from "@/components/Icon/longRight";

export default {
  name: 'menu_m',
  components: {
    LongRightSvg,
    RightSvg
  },
  data() {
    return {
      openKeys: ['sub1'],
      selectedKeys: [],
      introduceList: [
        'team',
        'partner',
        'technology',
        'qualifications'
      ]
    };
  },
  watch: {
    '$route': {
      handler(v) {
        if (v.name === 'introduce') {
          this.selectedKeys = [v.params.type]
        } else {
          this.selectedKeys = [v.name]
        }
      },
      deep: true
    }
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      this.openKeys = latestOpenKey ? [latestOpenKey] : [];
    },
    clickHandle({
                  item,
                  key
                }) {
      this.$emit('clickHandle')
      // console.log(this.$route, key)
      if (this.$route.name !== key) {
        this.selectedKeys = [key]
        if (this.introduceList.includes(key)) {
          console.log(this.$route)
          if (this.$route.params.type === key) {
            return
          }
          this.$router.push({
            name: 'introduce',
            params: {
              type: key
            }
          })
          return
        }
        this.$router.push({name: key})
      }
    }
  },
};
</script>
<style lang="less" scoped>
.ant-menu ::v-deep {
  * {
    font-size: 18px;
    font-family: "AlibabaPuHuiTi-2-20";
    color: #000;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
  }

  li {
    font-weight: bold;
    font-size: 18px;
  }

  .ant-menu-sub {
    li {
      font-size: 16px;
      font-weight: normal;


    }

  }

  .ant-menu-submenu-active {
    .ant-menu-submenu-title {
      color: #3C60EA;
      background: transparent !important;
    }
  }

  .ant-menu-item-selected, .ant-menu-submenu-selected {
    color: #3C60EA;
    background: transparent !important;
  }

  .ant-menu-item:active, .ant-menu-submenu-title:active {
    background: transparent !important;
  }
}

.icon {
  height: 8px;
  margin-left: 4px;
}

/deep/ .case {
  height: max-content !important;
  padding: 0 20px !important;
}

/deep/ .case-item {
  * {
    //font-family: "AlibabaPuHuiTi-2-20";
  }

  width: 100%;
  border-radius: 18px;
  background: #F2F5FA;
  display: flex;
  align-items: center;
  padding: 16px;
  line-height: 1;

  img {
    width: 55px;
    height: 55px;
    margin-right: 20px;
  }

  .text {
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #4d51f1;
    }

    .title-en {
      font-size: 10px;
    }

    .title-tip {
      font-size: 14px;
    }
  }
}

/deep/ .case-more {
  width: 100%;
  height: 46px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4D51F1;
  border: 2px solid #F1F5FB;
  border-radius: 18px;
}
</style>
